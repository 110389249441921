import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <div>
                    <h1><p>Tamago Trading</p></h1>
                    <hr className="App-divider"/>
                    <sub className="App-sub"><h2>Miami, FL</h2></sub>
                </div>
            </header>

        </div>
    );
}

export default App;
